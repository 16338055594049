import React from "react";
import "./Book.css";

const Book = () => {
  return (
    <div className="container" id="book">
      <div className="card book-container rounded-5 shadow">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-9">
              <h3 className="sub-title center-on-mobile">
                Una metodología efectiva para tus proyectos de software.
              </h3>
            </div>
            <div className="col-lg-3 d-flex justify-content-center align-items-center">
              <a
                href="https://api.whatsapp.com/send?phone=50762064626&text=Buen%20d%C3%ADa!%20Me%20gustar%C3%ADa%20agendar%20una%20consultor%C3%ADa%20gratuita%20para%20evaluar%20mi%20proyecto."
                className="btn btn-primary mb-3 mt-3"
              >
                <i className="bi bi-whatsapp"></i> Consultoría gratuita
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
