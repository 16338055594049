import React from "react";
// import Typewriter from "typewriter-effect";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="container" id="hero">
      <div className="row reverse-flow-on-mobile">
        <div className="col-lg-6 p-3 d-flex flex-column justify-content-center align-items-start">
          <h1 className="main-title mb-0">
            Soluciones de software a la medida. <br />
          </h1>
          {/* <div className="mt-3">
            <p className="sub-title-2 text-primary mt-0">
              <Typewriter
                options={{
                  strings: [
                    "Aplicaciones Web.",
                    "Sistemas de Inventario.",
                    "Gestión de clientes (CRM).",
                    "Manejo de Carga.",
                    "Gestión de Recursos.",
                    "Sistemas de Facturación.",
                    "Desarrollo de APIs.",
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 50,
                }}
              />
            </p>
          </div> */}
          <div className="d-flex flex-column align-items-start mt-3">
            <span className="h5">¿Tienes un proyecto en mente?</span>
            <a href="#start-a-project" className="btn brand-btn rounded-3">
              Conozcámonos
            </a>
          </div>
        </div>
        <div className="col-lg-6 d-flex justify-content-center align-items-center mb-3">
          <img
            className="img-fluid"
            src="assets/img/rocket-launch.svg"
            alt="desarrollo web panama"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
