import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <div className="container" id="services">
      <div className="d-flex justify-content-center align-items-center">
        <div className="services-title-container">
          <h3 className="sub-title mt-0 text-center">
            Desarrollamos productos y soluciones de software en la nube.
          </h3>
          <p className="mb-0 text-center">
            En <strong>Aben TD</strong> nos especializamos en la gestión de
            recursos y automatización de procesos empresariales. Digitalizamos
            tu modelo de negocio, desde una landing page hasta una
            infraestructura web completa.
          </p>
        </div>
      </div>

      <div className="row services-cards">
        <div className="col-lg-4">
          <div className="row">
            <div className="col-lg-12 single-service">
              <h5 className="sub-title-2">Desarrollo de Aplicaciones Web.</h5>
              <p className="mb-0">
                Un equipo de <strong>profesionales ágiles</strong> está listo
                para analizar los requerimientos de tu proyecto y modelar la
                mejor solución posible para tu negocio
              </p>
            </div>
            <div className="col-lg-12 single-service">
              <h5 className="sub-title-2">Desarrollo de APIs.</h5>
              <p className="mb-0">
                Creamos un ecosistema de integración de datos entre aplicaciones
                y servicios web que te permitirá aumentar la productividad de tu
                negocio.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 single-service d-flex flex-wrap align-items-center">
          <img
            src="assets/img/illustration-1-bg.svg"
            alt="desarrollo de software en panamá"
            className="img-fluid"
          />
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="col-lg-12 single-service">
              <h5 className="sub-title-2">Diseño Web UI/UX.</h5>
              <p className="mb-0">
                Diseñamos tu sitio web, con una experiencia de usuario que te
                ayudará a captar más clientes y a mejorar tu presencia en
                internet.
              </p>
            </div>
            <div className="col-lg-12 single-service">
              <h5 className="sub-title-2">Consultoría Web.</h5>
              <p className="mb-0">
                ¿Tienes dudas? ¿No sabes cómo funciona el desarrollo web, o
                quieres saber cuál es la mejor solución para tu negocio? <br />
                <a href="#start-a-project">Conozcámonos</a> y resolvamos todas
                esas dudas, te ayudaremos a tomar{" "}
                <strong>la mejor decisión.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
