import React from "react";
import "./startProject.css";

const StartProject = () => {
  return (
    <div
      className="container bg-super-light rounded-5 shadow"
      id="start-a-project"
    >
      <div className="mt-3">
        <div className="row">
          <div className="col-lg-6 start-project-title">
            <h3 className="sub-title">Nuestra metodología de trabajo.</h3>
            <p>
              Una buena idea puede convertirse en una{" "}
              <strong>buena solución</strong> solo si está desarrollada con una{" "}
              <strong>buena metodología.</strong>
            </p>
            <a href="#projects" className="btn brand-btn rounded-3 mb-3">
              Casos de éxito
            </a>
            <div className="start-project-img-container">
              <img
                src="assets/img/illustration-2-bg.svg"
                alt="desarrollo de software en panama"
                className="img-fluid start-project-img"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-center align-items-center ">
                <div className="card start-project-card">
                  <div className="card-body">
                    <h5 className="sub-title-2">1. Planificar</h5>
                    <p>
                      Definimos el alcance del proyecto, establecemos{" "}
                      <strong>estándares de calidad</strong> deseados y procesos
                      a seguir.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 d-flex justify-content-center align-items-center ">
                <div className="card start-project-card">
                  <div className="card-body">
                    <h5 className="sub-title-2">2. Analizar</h5>
                    <p>
                      <strong>Analizamos los requerimientos</strong> del
                      proyecto, el diseño, las interfaces de usuario y la
                      experiencia del mismo.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 d-flex justify-content-center align-items-center ">
                <div className="card start-project-card">
                  <div className="card-body">
                    <h5 className="sub-title-2">3. Validar modelo.</h5>
                    <p>
                      Presentamos una <strong>previa</strong> de alta fidelidad
                      del diseño de las{" "}
                      <strong>interfaces de la aplicación.</strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 d-flex justify-content-center align-items-center ">
                <div className="card start-project-card">
                  <div className="card-body">
                    <h5 className="sub-title-2">4. Desarrollar</h5>
                    <p>
                      Con los requerimientos y el diseño validados, empezamos a{" "}
                      <strong>desarrollar la aplicación.</strong>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 d-flex justify-content-center align-items-center ">
                <div className="card start-project-card">
                  <div className="card-body">
                    <h5 className="sub-title-2">5. Entregar</h5>
                    <p>
                      Una beta de la aplicación está lista para ser presentada
                      en un <strong>ambiente de producción</strong> controlado.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 d-flex justify-content-center align-items-center ">
                <div className="card start-project-card">
                  <div className="card-body">
                    <h5 className="sub-title-2">6. Mantener</h5>
                    <p>
                      Con nuestro seguro de calidad como servicio, mantenemos tu
                      aplicación{" "}
                      <strong>fuera de bugs y errores no deseados.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartProject;
