import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  return (
    <div>
      <nav className="navbar navbar-light bg-super-light mt-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 mb-3">
              <Link className="navbar-brand" to="/">
                <img
                  width="120"
                  src="assets/img/logo-abendevs-3.svg"
                  alt="logo abendevs"
                />
              </Link>
              <p className="fs-14 mt-2 w-75">
                Gestionamos recursos y automatizamos procesos empresariales con
                productos y soluciones de <strong>software a la medida.</strong>
              </p>
              <a
                href="https://instagram.com/abendevs"
                className="no-link fs-25"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://wa.me/message/BWZPC2MHQUHVJ1"
                className="no-link ms-3 fs-25"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-whatsapp"></i>
              </a>
            </div>
            <div className="col-sm-8 mb-3">
              <div className="row">
                <div className="col-sm-3 mb-3 fs-14">
                  <h6>
                    <strong className="">Productos</strong>
                  </h6>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a
                        href="https://elizaapp.com?utm-source=abendevs.com"
                        target={"_blank"}
                        className="nav-link reversed-link ps-0"
                      >
                        ElizaApp{" "}
                        <i className="bi bi-box-arrow-up-right ms-1 small"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-3 mb-3 fs-14">
                  <h6>
                    <strong className="">Enlaces</strong>
                  </h6>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a href="#home" className="nav-link reversed-link ps-0">
                        Inicio
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#services"
                        className="nav-link reversed-link ps-0"
                      >
                        Servicios
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#projects"
                        className="nav-link reversed-link ps-0"
                      >
                        Casos de éxito
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#start-a-project"
                        className="nav-link reversed-link ps-0"
                      >
                        Metodología
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-3 mb-3 fs-14">
                  <h6>
                    <strong className="">Servicios</strong>
                  </h6>
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a
                        href="#services"
                        className="nav-link reversed-link ps-0"
                      >
                        Desarrollo Web
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#services"
                        className="nav-link reversed-link ps-0"
                      >
                        Diseño UI/UX
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#services"
                        className="nav-link reversed-link ps-0"
                      >
                        Consultoría Web
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#services"
                        className="nav-link reversed-link ps-0"
                      >
                        Desarrollo de APIs
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-3 mb-3 fs-14">
                  {/* Address */}
                  <h6>
                    <strong className="">Contáctanos</strong>
                  </h6>
                  <ul className="nav flex-column">
                    <li className="nav-item">+507 6206-4626</li>
                    <li className="nav-item">info@abendevs.com</li>
                    <li className="nav-item">Panamá Pacífico, Panamá</li>
                    <li className="nav-item">
                      <a
                        href="https://wa.me/message/BWZPC2MHQUHVJ1"
                        className="btn btn-primary fs-15 mt-2"
                      >
                        <i className="bi bi-whatsapp"></i> WhatsApp
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav className="navbar navbar-light bg-super-light p-2 border-top">
        <div className="container-fluid justify-content-center">
          <ul className="nav fs-12">
            <li className="nav-link no-link">
              &copy; {new Date().getFullYear()} Todos Los Derechos Reservados.
            </li>
            <li className="nav-link no-link">Aben Technology Developments.</li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Footer;
