import React from "react";
import "./Faqs.css";

const Faqs = () => {
  return (
    <div className="container" id="faqs">
      <div className="d-flex justify-content-center align-items-center">
        <div className="services-title-container">
          <h3 className="sub-title mt-0 text-center">Preguntas frecuentes</h3>
          <p className="mb-0 text-center">
            Damos respuesta a las preguntas más frecuentes sobre nuestros
            servicios. Si no encuentras la respuesta a tu pregunta, puedes
            solicitar una consultoría gratuita aquí.
          </p>
        </div>
      </div>
      <div className="mt-5">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <strong>
                  ¿Por qué se realiza un análisis de requerimientos previo al
                  desarrollo de mi proyecto?
                </strong>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                El análisis de requerimientos es fundamental para establecer el
                alcance y los límites de tu proyecto, si no se analiza tu
                proyecto es imposible definir el costo ni la infraestructura
                necesaria para llevarlo a cabo.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                <strong>
                  ¿Por qué es importante el mantenimiento preventivo de mi
                  proyecto?
                </strong>
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Como su nombre lo indica, hace falta realizarlo para prevenir
                errores o bugs que puedan afectar el rendimiento de tu proyecto.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                <strong>¿Desarrollan software para cualquier industria?</strong>
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Nuestro equipo de desarrollo está capacitado para analizar los
                requerimientos de cualquier industria. <br />
                Tenemos experiencia desarrollando software para diversas
                industrias, como: construcción, comercio, educación, entre
                otras.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
