import React from "react";
import "./Projects.css";

const Projects = () => {
  return (
    <div className="container" id="projects">
      <div className="d-flex align-items-center">
        <div className="services-title-container">
          <h3 className="sub-title mt-0">Casos de éxito.</h3>
          <p className="mb-0">
            Hemos {""}
            <strong>optimizado</strong> procesos de logística y administración
            en <strong>más de 20 proyectos</strong> de desarrollo tecnológico,
            lo cual ha beneficiado a más de <strong>8,000 usuarios</strong>{" "}
            alrededor del mundo. Hemos mejorado su experiencia,{" "}
            <strong>la gestión de sus recursos</strong> y el{" "}
            <strong>acceso a sus datos de manera descentralizada.</strong>
          </p>
        </div>
      </div>
      <div className="mt-5">
        <div className="row">
          <div className="col-lg-4 mb-3">
            <div className="card bg-white border-0">
              <img
                src="./assets/img/projects/panaweb.jpg"
                className="card-img-top rounded-5"
                alt="Desarrollo web abendevs - PANAWEB"
              />
              <div className="card-body">
                <h5 className="card-title">
                  <strong>Panaweb - ERP</strong>
                </h5>
                <p className="card-text">
                  Infraestructura web para la descentralización de datos y
                  gestión de recursos empresariales.
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="me-2">
                    <span className="d-flex flex-column">
                      <strong>51%</strong>
                      <span className="fs-12">
                        Procesos administrativos optimizados
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="d-flex flex-column">
                      <strong>61%</strong>
                      <span className="fs-12">Mayor efectividad laboral</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="card bg-white border-0">
              <img
                src="./assets/img/projects/qskillz.jpg"
                className="card-img-top rounded-5"
                alt="Desarrollo web abendevs - Qskillz"
              />
              <div className="card-body">
                <h5 className="card-title">
                  <strong>Qskillz Tutores - CRM</strong>
                </h5>
                <p className="card-text">
                  Manejo de agenda de servicios de tutoría y seguimiento de
                  cartera de clientes.
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="me-2">
                    <span className="d-flex flex-column">
                      <strong>37%</strong>
                      <span className="fs-12">
                        Mayor gestión efectiva del tiempo
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="d-flex flex-column">
                      <strong>10,000+</strong>
                      <span className="fs-12">Servicios generados</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card bg-white border-0">
              <img
                src="./assets/img/projects/auwcargo.jpg"
                className="card-img-top rounded-5"
                alt="Desarrollo web abendevs - AUWCargo"
              />
              <div className="card-body">
                <h5 className="card-title">
                  <strong>AUW Cargo - Panel de clientes</strong>
                </h5>
                <p className="card-text">
                  Visualización de pedidos, historial de facturación y
                  seguimiento de carga en la nube.
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="me-2">
                    <span className="d-flex flex-column">
                      <strong>80%</strong>
                      <span className="fs-12">
                        Mayor efectividad de atención al cliente
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="d-flex flex-column">
                      <strong>2,000+</strong>
                      <span className="fs-12">Usuarios beneficiados</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
