import React from "react";
import Hero from "./components/hero/Hero";
import Reviews from "./components/reviews/Reviews";
import Services from "./components/services/Services";
import Stack from "./components/stack/Stack";
import StartProject from "./components/startproject/StartProject";
import Projects from "./components/projects/Projects";
import Book from "./components/book/Book";
import Faqs from "./components/faqs/Faqs";
const Home = () => {
  return (
    <div id="home">
      <Hero />
      <Reviews />
      <Services />
      <Stack />
      <StartProject />
      <Projects />
      <Book />
      <Faqs />
    </div>
  );
};

export default Home;
