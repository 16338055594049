import React from "react";
import { Link, useLocation } from "react-router-dom";

const NotFound = () => {
  let location = useLocation();
  return (
    <div className="container text-center mt-5">
      <h1>
        <strong>Error 404</strong>
      </h1>
      <h3 className="mt-3">
        No existe la página <code>{location.pathname}</code>
      </h3>
      <p className="mt-5">
        <Link to="/">&larr; Regresar al inicio</Link>
      </p>
    </div>
  );
};

export default NotFound;
