import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  let [NavbarClass, changeNavbarClass] = useState(
    "navbar sticky-top navbar-expand-lg navbar-light bg-white"
  );
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 75) {
        changeNavbarClass(
          "navbar sticky-top navbar-expand-lg navbar-light bg-white navbar-scrolled"
        );
      } else {
        changeNavbarClass(
          "navbar sticky-top navbar-expand-lg navbar-light bg-white"
        );
      }
    });
  }, []);

  return (
    <nav className={NavbarClass}>
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            width="135"
            src="assets/img/logo-abendevs-2.svg"
            alt="logo abendevs"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="#home" className="nav-link">
                Inicio
              </a>
            </li>
            <li className="nav-item">
              <a href="#services" className="nav-link">
                Servicios
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Productos
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a
                    className="dropdown-item"
                    href="https://elizaapp.com?utm-source=abendevs.com"
                    target={"_blank"}
                  >
                    ElizaApp{" "}
                    <i className="bi bi-box-arrow-up-right ms-1 small"></i>
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="#projects" className="nav-link">
                Casos de éxito
              </a>
            </li>
            <li className="nav-item">
              <a href="#start-a-project" className="btn brand-btn rounded-3">
                Conozcámonos
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
