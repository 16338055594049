import React from "react";
import "./Stack.css";

const Stack = () => {
  return (
    <div id="stack">
      <div className="container">
        <div className="stack-row">
          <div className="stack-col">
            <img
              src="assets/img/tools/reactjs.svg"
              className="img-gray-filter stack-img"
              alt="desarrollo de software en panama react js"
            />
          </div>
          <div className="stack-col">
            <img
              src="assets/img/tools/php.svg"
              className="img-gray-filter stack-img"
              alt="desaroollo de software en panama php"
            />
          </div>
          <div className="stack-col">
            <img
              src="assets/img/tools/javascript.svg"
              className="img-gray-filter stack-img"
              alt="desarrollo de software en panama javascript"
            />
          </div>
          <div className="stack-col">
            <img
              src="assets/img/tools/github.svg"
              className="img-gray-filter stack-img"
              alt="desarrollo de software en panama github"
            />
          </div>
          <div className="stack-col">
            <img
              src="assets/img/tools/digitalocean.svg"
              className="img-gray-filter stack-img"
              alt="desarrollo de software en panama digitalocean"
            />
          </div>
          <div className="stack-col">
            <img
              src="assets/img/tools/mysql.svg"
              className="img-gray-filter stack-img"
              alt="desarrollo de software en panama mysql"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stack;
