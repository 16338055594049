import React from "react";
import "./Reviews.css";

const Reviews = () => {
  return (
    <div className="container mt-5 pt-3">
      <div className="card bg-reviews rounded-5 shadow">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 reviews">
              <p>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
              </p>
              <p>
                Nos ayudaron a <strong>digitalizar nuestra operación</strong>{" "}
                diaría, lo que nos <strong>ahorró</strong> mucho{" "}
                <strong>tiempo y dinero</strong>.
              </p>
              <p>
                <strong>Larisa Abramovich</strong> <br />
                <span className="fs-12">Gerente General Panaweb, S.A.</span>
              </p>
              <div className="client-img-container">
                <img
                  src="assets/img/clients/logo-panaweb.png"
                  className="img-gray-filter clients-img"
                  alt="desarrollo de software en panama panaweb"
                />
              </div>
            </div>
            <div className="col-lg-4 reviews">
              <p>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
              </p>
              <p>
                <strong>Disminuímos en un 83%</strong> la carga laboral de
                nuestros empleados con la solución implementada por{" "}
                <strong>Aben TD.</strong>
              </p>
              <p>
                <strong>Stephania de Los Santos</strong> <br />
                <span className="fs-12">
                  Gerente General Vip Global Group, S.A.
                </span>
              </p>
              <div className="client-img-container">
                <img
                  src="assets/img/clients/logo-vipexpress.png"
                  className="img-gray-filter clients-img"
                  alt="desarrollo de software en panama vip express"
                />
              </div>
            </div>
            <div className="col-lg-4 reviews">
              <p>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
                <i className="bi bi-star-fill text-warning"></i>
              </p>
              <p>
                Con <strong>Aben TD</strong> desarrollamos una{" "}
                <strong>solución óptima</strong> para nuestros clientes que
                residen en áreas de difícil acceso.
              </p>
              <p>
                <strong>Angela Escobar</strong> <br />
                <span className="fs-12">
                  Gerente General Global Communication Group, S.A.
                </span>
              </p>
              <div className="client-img-container">
                <img
                  src="assets/img/clients/logo-gcg.svg"
                  className="img-gray-filter clients-img"
                  alt="desarrollo de software en panama global communication group"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
